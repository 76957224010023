import React from "react";
import { connect } from "react-redux";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactBody from "react-body";
// import { Link } from "gatsby";
// import VolunteerForm from "../components/volunteerForm";
import Contribute from "../components/contribute";
import EmailForm from "../components/emailForm";
// import InstaPhotos from "../components/instaPhotos";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const IndexPage = ({ language }) => {
  return (
    <Layout id="home">
      <SEO title="Home" />
      <div className="hero home-hero">
        <div className="hero-bg primary">
          <div className="hero-bg-head" />
        </div>
        <div className="join-form">
          {/*<div className="action-form" id={`can-form-area-${formID}`} />
          <div id="email-scripts" />*/}
          <EmailForm />

          <div className="join-social">
            <a
              href="https://twitter.com/PiSanchezNYC"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon icon-twitter" aria-label="twitter" />
            </a>
            <a
              href="https://www.facebook.com/PierinaSanchezNYC"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon icon-facebook" aria-label="facebook" />
            </a>
            <a
              href="https://www.instagram.com/PierinaSanchezNYC"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon icon-instagram" aria-label="instagram" />
            </a>
            {/*<a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><i className="icon icon-youtube" /></a>*/}
          </div>
        </div>
      </div>
      <section className="content slant dark home-video">
        <div className="content-inner">
          <div className="content-video">
            <h3>{language === "es" ? "Conocer a Pierina" : "Meet Pierina"}</h3>
            {language === "es" ? (
              // no subs es: OrRnKI1z9pY
              // no subs en: 0Q-Z4175xGU
              // subs es: 6fKiQjAV5-8
              // subs en: ohOgTgBl8HI
              <iframe
                width="780"
                height="438"
                src="https://www.youtube.com/embed/OrRnKI1z9pY"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="Pierina Sanchez 2021"
                allowFullScreen
              ></iframe>
            ) : (
              <iframe
                width="780"
                height="438"
                src="https://www.youtube.com/embed/0Q-Z4175xGU"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="Pierina Sanchez 2021"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </div>
      </section>
      {/* <section className="content slant home-fight">
        <div className="content-inner">
          <div className="content-img">
            <img src={require('../images/map-bronx.png')} alt="Pierina fights for the Bronx" />
          </div>
          <div className="content-text">
            <h3>{language === 'es' ? "Pierina lucha por el Bronx" : "Pierina fights for the Bronx"}</h3>
            <p>{language === 'es' ? 
              "Pierina es Afro-Dominicana, hija de inmigrantes y residente del Bronx de toda la vida, nacida en University Heights y criada en Kingsbridge. Pierina creció mirando el fuerte trabajo de sus mayores – vendiendo naranjas, conduciendo taxis, cuidando edificios y escuelas – todo para asegurar que su generación tuviese oportunidad de educación superior y oportunidad en este país." 
              : "Pierina is an Afro-Dominicana, daughter of immigrants, and lifelong Bronxite, born in University Heights and raised in Kingsbridge. Pierina grew up watching the hard work of her elders – selling oranges, driving taxis, cleaning buildings and schools – to ensure her generation had a chance at higher education and opportunity."}</p>
            <p>{language === 'es' ? 
              "Desde la Casa Blanca hasta la Junta Comunitaria 5 del Bronx, y desde abogacía en la planificación urbana hasta el Ayuntamiento, Pierina ha sido y seguirá siendo una luchadora para las familias inmigrantes, la justicia racial, el alojamiento asequible y los buenos empleos."
              : "From the White House to the community board, and from advocacy to City Hall – Pierina has been and will continue to be a fighter for immigrant families, racial justice, affordable housing and good jobs."}</p>
          </div>
        </div>
      </section> */}
      {/* <section className="content accent home-running">
        <div className="slant-bg" />
        <div className="content-inner">
          <div className="content-text">
            <h3>
              {language === "es"
                ? "Pierina's First Year"
                : "Pierina's First Year"}
            </h3>
            <p>
              {language === "es"
                ? "In her first year, Pierina delivered. She brought back $30 million to upgrade our schools, build a new community center and begin the remediation of the Kingsbridge Armory. Her team served 3,000 neighbors directly in the office, and she passed legislation protecting tenants rights, women’s rights and our children."
                : "In her first year, Pierina delivered. She brought back $30 million to upgrade our schools, build a new community center and begin the remediation of the Kingsbridge Armory. Her team served 3,000 neighbors directly in the office, and she passed legislation protecting tenants rights, women’s rights and our children."}
            </p>
            <p>
              {language === "es"
                ? "Pierina’s running for re-election to continue the critical work of delivering for our community, help our families recover and rebuild from the Pandemic, and bring the Bronx back stronger."
                : "Pierina’s running for re-election to continue the critical work of delivering for our community, help our families recover and rebuild from the Pandemic, and bring the Bronx back stronger."}
            </p>
          </div>
        </div>
      </section> */}
      {/* <section className="content slant home-issues">
        <div className="content-inner">
          <div className="issues-content">
            <div className="content-text">
              <h3>
                {language === "es" ? "Sobre los problemas" : "On the issues"}
              </h3>
              <p>
                {language === "es"
                  ? "Pierina’s agenda is all about lifting up the resilience of our community."
                  : "Pierina’s agenda is all about lifting up the resilience of our community."}
              </p>
              <ul>
                <li>
                  <AnchorLink to="/impact#community">
                    <div className="issue-header">
                      <div className="issue-icon">
                        <img
                          src={require("../images/icons/003-support.svg")}
                          alt="community"
                        />
                      </div>
                      <h4>
                        {language === "es"
                          ? `Reinvesting in Our Community`
                          : `Reinvesting in Our Community`}
                      </h4>
                    </div>
                    <p>
                      {language === "es"
                        ? `Our people are our district’s greatest resource, we must cultivate each other for our mutual success.`
                        : `Our people are our district’s greatest resource, we must cultivate each other for our mutual success.`}
                      <span className="btn btn-text btn-accent btn-small">
                        {language === "es" ? "sigue leyendo" : "keep reading"}
                      </span>
                    </p>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/impact#education">
                    <div className="issue-header">
                      <div className="issue-icon">
                        <img
                          src={require("../images/icons/education.svg")}
                          alt="education"
                        />
                      </div>
                      <h4>
                        {language === "es"
                          ? `Education & Youth Justice`
                          : `Education & Youth Justice`}
                      </h4>
                    </div>
                    <p>
                      {language === "es"
                        ? `Every child deserves access to a quality public education.`
                        : `Every child deserves access to a quality public education.`}
                      <span className="btn btn-text btn-accent btn-small">
                        {language === "es" ? "sigue leyendo" : "keep reading"}
                      </span>
                    </p>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/impact#housing">
                    <div className="issue-header">
                      <div className="issue-icon">
                        <img
                          src={require("../images/icons/apartment.svg")}
                          alt="housing"
                        />
                      </div>
                      <h4>
                        {language === "es"
                          ? `Housing is a Human Right`
                          : `Housing is a Human Right`}
                      </h4>
                    </div>
                    <p>
                      {language === "es"
                        ? `Every one of us deserves to live in and even own a stable and healthy home.`
                        : `Every one of us deserves to live in and even own a stable and healthy home.`}
                      <span className="btn btn-text btn-accent btn-small">
                        {language === "es" ? "sigue leyendo" : "keep reading"}
                      </span>
                    </p>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/impact#opportunity">
                    <div className="issue-header">
                      <div className="issue-icon">
                        <img
                          src={require("../images/icons/006-help.svg")}
                          alt="opportunity"
                        />
                      </div>
                      <h4>
                        {language === "es"
                          ? `Safe Communities through Opportunity`
                          : `Safe Communities through Opportunity`}
                      </h4>
                    </div>
                    <p>
                      {language === "es"
                        ? `Every family should feel safe in their neighborhood.`
                        : `Every family should feel safe in their neighborhood.`}
                      <span className="btn btn-text btn-accent btn-small">
                        {language === "es" ? "sigue leyendo" : "keep reading"}
                      </span>
                    </p>
                  </AnchorLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      {/*<InstaPhotos />*/}
      {/*<VolunteerForm />*/}
      <Contribute />
      <ReactBody className="home" />
    </Layout>
  );
};

const mapStateToProps = ({ language }) => {
  return { language };
};

export default connect(mapStateToProps)(IndexPage);
